import helpers from '@/helpers'
export const somaVacinaDoseCampo = (vacina, campo, agrupado = true) => {
    let
        comFomentoTrabalhadores = vacina.doses.com_fomento.trabalhador,
        comFomentoTerceiros = vacina.doses.com_fomento.terceiro,
        comFomentoDependentes = vacina.doses.com_fomento.dependente,
        semFomentoTrabalhadores = vacina.doses.sem_fomento.trabalhador,
        semFomentoTerceiros = vacina.doses.sem_fomento.terceiro,
        semFomentoDependentes = vacina.doses.sem_fomento.dependente;

    const comFomento = comFomentoTrabalhadores[campo] + comFomentoTerceiros[campo] + comFomentoDependentes[campo],
        semFomento = semFomentoTrabalhadores[campo] + semFomentoTerceiros[campo] + semFomentoDependentes[campo];

    return agrupado ? comFomento + semFomento : {
        comFomento,
        semFomento,
    }
};

export const groupByKey = (array, key) => {

    let incentivos = array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
            [obj[key]]: (hash[obj[key]] || []).concat(obj)
        })
    }, {})

    let estimativas = [];
    if (incentivos[2] !== undefined && incentivos[2].length > 0) {
        estimativas.push(incentivos[2])
    }
    if (incentivos[1] !== undefined && incentivos[1].length > 0) {
        estimativas.push(incentivos[1])
    }

    return estimativas;
}

export const ordenarSequencia = (a, b) => {
    if (a.sequencia < b.sequencia) {
        return -1;
    }
    if (a.sequencia > b.sequencia) {
        return 1;
    }
    return 0;
};

export const zerarDoses = (doses) => {
    let tipoIncentivo = ['com_fomento', 'sem_fomento'],
        tipoColaborador = ['trabalhador', 'dependente', 'terceiro'],
        zerar = { qtd: 0, total: 0 },
        retornoZerado = {};

    tipoIncentivo.forEach((tipo) => {
        if (typeof(doses[tipo]) != 'undefined') {
            retornoZerado[tipo] = {};
            let doseTipo = doses[tipo];
            tipoColaborador.forEach((colaborador) => {
                if (typeof(doseTipo[colaborador]) != 'undefined') {
                    retornoZerado[tipo][colaborador] = {...doseTipo[colaborador], ...zerar }
                }
            });
        }
    });

    return retornoZerado;
}


export const totalValoresDosesEstrutura = (idEmpresa) => {
    let categorias = [1, 2, 3, 4],
        totalValoresDoses = {};

    categorias.forEach((idCategoria) => {
        totalValoresDoses[idCategoria] = {
            "id_categoria": idCategoria,
            "id_empresa": idEmpresa,
            "quantidade_total": 0,
            "valor_total": 0
        };
    })

    return totalValoresDoses;
}

export const handleVacinaDoses = (vacina, idEmpresa, ehEmpresa = false) => {
    const tipoDose = { comFomento: 'com_fomento', semFomento: 'sem_fomento' },
        tipoColaboradores = [
            'trabalhador',
            'dependente',
            'terceiro',
        ];
    let todasDoses = [],
        agrupadorDoses = [],
        totalValoresDoses = totalValoresDosesEstrutura(idEmpresa),
        totalTipoPreco = {
            com_fomento: 0,
            sem_fomento: 0
        };

    tipoColaboradores.forEach((tipoColaborador) => {
        try {
            vacina.doses[tipoDose.comFomento][tipoColaborador]['nome_categoria'] = tipoColaborador;
            vacina.doses[tipoDose.semFomento][tipoColaborador]['nome_categoria'] = tipoColaborador;

            let doseComFomento = vacina.doses[tipoDose.comFomento][tipoColaborador],
                doseSemFomento = vacina.doses[tipoDose.semFomento][tipoColaborador],
                idCategoria = doseComFomento.id_categoria || doseSemFomento.id_categoria || null;

            agrupadorDoses.push(doseComFomento);
            agrupadorDoses.push(doseSemFomento);

            // update totalizadores
            if (idCategoria != null) {
                totalValoresDoses[idCategoria].quantidade_total = doseComFomento.qtd + doseSemFomento.qtd;
                totalValoresDoses[idCategoria].valor_total = doseComFomento.total + doseSemFomento.total;
            }

            totalTipoPreco.com_fomento += doseComFomento.qtd
            totalTipoPreco.sem_fomento += doseSemFomento.qtd
        } catch (error) {}
    })

    agrupadorDoses.forEach((dose) => {
        if (dose.qtd > 0) {
            todasDoses.push({
                id_categoria: dose.id_categoria,
                id_empresa: idEmpresa,
                quantidade: dose.qtd,
                valor: dose.total,
                id_incentivo: dose.id_incentivo,
                id_pedido_vacina: dose.id_pedido_vacina,
                nome_categoria: dose.nome_categoria,
                possui_valor_diferenciado: dose?.possui_valor_diferenciado ?? false
            });
        }
    });

    let totalizador = !ehEmpresa ? totalTipoPreco : {
        "com_fomento": totalTipoPreco.sem_fomento,
        "sem_fomento": totalTipoPreco.com_fomento
    }

    return {
        todasDoses: todasDoses,
        totalValoresDoses: totalValoresDoses,
        totalTipoPreco: totalizador
    };
}


export const formataEstruturaEmpresa = (empresa, carrinho, sequencia) => {
    sequencia++;
    let identificadores = {
        sequencia: sequencia,
        updatedAt: helpers.geraNumeroAleatorio(),
        observacao: null
    }

    let colaboradores = {
        trabalhador: empresa.trabalhador,
        dependente: empresa.dependente,
        terceiro: empresa.terceiro,
    };

    return {
        ...empresa,
        ...identificadores,
        ... { colaboradores },
        ... { carrinho }
    }
}

export const getResumoVacinasFormatada = (vacinas = [], ehEmpresa = false) => {
    let resumoVacinas = [];

    const detalheEstivimativaComFomento = {
            tipo: 'Com fomento',
            titulo: !ehEmpresa ? 'Disponível com fomento' : null,
            key: 'com_fomento',
        },
        detalheEstivimativaSemFomento = {
            tipo: 'Sem fomento',
            titulo: !ehEmpresa ? 'Disponível sem fomento' : null,
            key: 'sem_fomento',
        };

    try {
        vacinas.forEach((vacinaObj) => {
            let estimativas = {};
            let estimativasAgrupadas = groupByKey(vacinaObj.estimativas, 'id_incentivo')

            estimativasAgrupadas.forEach((estimativaObj) => {
                let estimativa = estimativaObj[0],
                    detalheEstivimativa = estimativa.id_incentivo == 2 ? detalheEstivimativaComFomento : detalheEstivimativaSemFomento,
                    keySesiOuEmpresa = ehEmpresa ? 'sem_fomento' : detalheEstivimativa.key;

                estimativas[keySesiOuEmpresa] = {
                    updatedAt: helpers.geraNumeroAleatorio(),
                    id_incentivo: estimativa.id_incentivo,
                    saldo_restante: estimativa.saldo_restante,
                    tipo: detalheEstivimativa.tipo,
                    titulo: detalheEstivimativa.titulo,
                    key: keySesiOuEmpresa,
                }
            });
            resumoVacinas.push({
                updatedAt: Math.random(),
                nome_tecnico: vacinaObj.nome_tecnico,
                id_vacina: vacinaObj.id_vacina,
                estimativas: estimativas
            })
        })
    } catch (error) {}

    return resumoVacinas;
}

export const getResumoPorVacinaEmpresa = (empresas = [], resumoVacinas = [], desabilitarEnvio = false) => {
    let empresaDistribuicaoTotal = {};
    try {
        empresas.forEach((empresa) => {
            empresaDistribuicaoTotal[empresa.id_empresa] = 0
            if (empresa.vacinas !== undefined) {
                empresa.vacinas.forEach((vacina) => {
                    let total = somaVacinaDoseCampo(vacina, 'qtd', false),
                        indexVacina = helpers.findIndexInArray(resumoVacinas, vacina, 'id_vacina'),
                        estimativaObj = resumoVacinas[indexVacina].estimativas,
                        comFomentoTotalObj = estimativaObj['com_fomento'] ?? null,
                        semFomentoTotalObj = estimativaObj['sem_fomento'] ?? null,
                        qtdRestanteComFomento = comFomentoTotalObj?.saldo_restante ?? 0,
                        qtdRestanteSemFomento = semFomentoTotalObj?.saldo_restante ?? 0;

                    empresaDistribuicaoTotal[empresa.id_empresa] += total.comFomento + total.semFomento

                    if (resumoVacinas[indexVacina].estimativas['com_fomento'] !== undefined) {
                        resumoVacinas[indexVacina].estimativas['com_fomento'].saldo_restante = qtdRestanteComFomento
                    }

                    if (resumoVacinas[indexVacina].estimativas['sem_fomento'] !== undefined) {
                        resumoVacinas[indexVacina].estimativas['sem_fomento'].saldo_restante = qtdRestanteSemFomento
                    }
                });
            }
        })
    } catch (error) {
        console.log("Erro ao gerar o resumo por empresa");
    }

    let desabilitarEnvioRascunho = Object.values(empresaDistribuicaoTotal).reduce((acc, valor) => acc + valor, 0) == 0;

    return {
        updatedAt: helpers.geraNumeroAleatorio(),
        vacinas: resumoVacinas,
        desabilitarEnvio: desabilitarEnvio,
        desabilitarEnvioRascunho: desabilitarEnvioRascunho,
    }
}


export const formataDadosParaSalvarPedido = (state, rascunho) => {
    let listaEmpresasOrdenadas = state.empresas.sort(ordenarSequencia),
        chooseUnit = state.chooseUnit,
        chooseCompany = state.chooseCompany,
        chooseCampaign = state.chooseCampaign,
        distribuicaoDoses = state.validacaoDosesEmpresa,
        empresas = [],
        ehEmpresa = chooseCompany != null,
        termoArr = state.termo




    listaEmpresasOrdenadas.forEach((empresaObj) => {
        let vacinas = [];
        empresaObj.vacinas.forEach((vacinaObj) => {
            let doses = handleVacinaDoses(vacinaObj, empresaObj.id_empresa, ehEmpresa),
                dosesTotalizadores = doses.totalValoresDoses,
                totalDoses = 0,
                totalValor = 0;

            for (let index in dosesTotalizadores) {
                totalDoses += dosesTotalizadores[index].quantidade_total;
                totalValor += dosesTotalizadores[index].valor_total;
            }

            if (doses.todasDoses.length > 0) {
                vacinas.push({
                    id_vacina: vacinaObj.id_vacina,
                    id_tipo_preco: empresaObj.id_tipo_preco,
                    id_empresa: empresaObj.id_empresa,
                    total_doses: totalDoses,
                    total_valor: totalValor,
                    doses: doses.todasDoses,
                    total_valores_doses: dosesTotalizadores,
                    total_tipo_preco: doses.totalTipoPreco
                });
            }
        });

        if (vacinas.length > 0) {
            empresas.push({
                id_empresa: empresaObj.id_empresa,
                nome_empresa: empresaObj.nome_empresa,
                id_tipo_preco: empresaObj.id_tipo_preco,
                endereco: empresaObj.endereco ?? empresaObj.empresa_endereco[0],
                sequencia: empresaObj.sequencia,
                observacao: empresaObj.observacao,
                soma_todas_doses: empresaObj.carrinho.doses,
                vacinas: vacinas,
            });
        }
    })

    let idUnidade = chooseUnit != null ? chooseUnit.id_unidade : chooseCompany.id_unidade;
    let idPedido = state.chooseOrder != null ? state.chooseOrder.id_pedido : null;

    return {
        id_pedido: idPedido,
        id_unidade: idUnidade,
        id_campanha: chooseCampaign.id_campanha,
        rascunho: rascunho,
        empresas: empresas,
        distribuicao_doses: distribuicaoDoses,
        termo: termoArr
    }
}

export const getNomeCategoriaNomeIncentivo = (idCategoria, idIncentivo) => {
    let nomeCategoria = '',
        nomeIncentivo = '';

    switch (idCategoria) {
        case 1:
            nomeCategoria = 'trabalhador';
            break;
        case 2:
            nomeCategoria = 'dependente';
            break;
        case 3:
            nomeCategoria = 'terceiro';
            break;
        case 4:
            nomeCategoria = 'convenio';
            break;
    }


    switch (idIncentivo) {
        case 1:
            nomeIncentivo = 'sem_fomento';
            break;
        case 2:
            nomeIncentivo = 'com_fomento';
            break;
    }

    return {
        nomeCategoria,
        nomeIncentivo
    }
}

export const handleRetornoApiPutPost = (errorsApi) => {
    let errors = [];
    if (typeof errorsApi == 'object') {
        Object.keys(errorsApi).forEach((key) => {
            if(key == 'msg'){
                errors.push(errorsApi[key][0])
            }else{
                errors[key] = errorsApi[key][0];
            }
        });
    } else {
        errors.push(errorsApi)
    }

    return errors;
}

export const handleEstimativasEmpresa = (estimativas) => {
    let totalizador = { '1': 0, '2': 0, };

    estimativas.forEach((estimativa) => {
        if (totalizador[estimativa.id_incentivo] == 0) {
            totalizador[estimativa.id_incentivo] = estimativa.saldo
        }
    })


    let saldo = totalizador['1'] + totalizador['2'];
    return estimativas.map((retorno) => {
        return {
            ...retorno,
            ... {
                saldo: saldo,
                saldo_restante: saldo
            }
        }
    });
}

export const adicionaAtributoPossuiPrecificacao = (arrayVacinas, empresa) => {
    return arrayVacinas.map((vacina) => {
        return {
            ...vacina,
            ... { possui_precificacao: vacina.id_tipo_preco === empresa.id_tipo_preco },
        }
    })
}

export const retornaMensagemSucessoPedidoAdesao = (ehRascunho, ehEmpresa = false) => {
    let
        msg = '',
        msgsSucessoPedidoAdesao = {
            rascunhoComSucesso: 'Rascunho salvo com sucesso!',
            pedidoComSucesso: 'Pedido concluído com sucesso.',
            adesaoComSucesso: 'Adesão concluída com sucesso.'
        };

    if (ehEmpresa) {
        msg = msgsSucessoPedidoAdesao.adesaoComSucesso;
    } else {
        msg = ehRascunho ? msgsSucessoPedidoAdesao.rascunhoComSucesso : msgsSucessoPedidoAdesao.pedidoComSucesso;
    }

    return msg;
};
